import { useEffect } from "react";
import {
  AutocompleteInput,
  BooleanInput,
  Create,
  CreateProps,
  Edit,
  EditProps,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  regex,
  useEditController,
} from "react-admin";
import { useWatch, useFormContext } from "react-hook-form";

const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,6}$/;

//
// Helper component that looks up an Entra tenant ID based
// on the domain
// Based on https://www.shawntabrizi.com/aad/does-company-x-have-an-azure-active-directory-tenant/
//
const EntraData = () => {
  const domain = useWatch({ name: "Domain" });
  const { setValue } = useFormContext();
  const isValidDomain = (domain: string) => {
    return domain && domainRegex.test(domain);
  };
  useEffect(() => {
    const getTenantId = async (domain: string) => {
      const url = `https://login.microsoftonline.com/${domain}/.well-known/openid-configuration`;
      const response = await fetch(url);
      if (!response.ok) {
        return;
      }
      const config = await response.json();
      const tokenEndpoint = config.token_endpoint;
      const tenantId = tokenEndpoint.split("/")[3];
      setValue("AzureTenant", tenantId);
    };
    if (isValidDomain(domain)) {
      getTenantId(domain);
    }
  }, [domain, setValue]);

  return (
    <TextInput
      label="Entra Tenant ID"
      source="AzureTenant"
      validate={required()}
      fullWidth
    />
  );
};

const ProviderData = () => {
  const provider = useWatch({ name: "Provider" });

  if (provider === "Okta" || provider === "OneLogin") {
    return (
      <>
        <TextInput
          label="Client ID"
          source="OpenIdConnectClientId"
          validate={required()}
          fullWidth
        />
        <TextInput
          label="Client Secret"
          source="OpenIdConnectClientSecret"
          validate={required()}
          fullWidth
        />
        <TextInput
          label={`${provider} Domain`}
          source="OpenIdConnectAuthority"
          validate={required()}
          type="url"
          fullWidth
        />
      </>
    );
  } else if (provider === "AzureAD") {
    return <EntraData />;
  }
  return <></>;
};

const TenantForm = () => {
  return (
    <SimpleForm>
      <TextInput source="Name" validate={required()} fullWidth />
      <TextInput
        source="Identifier"
        fullWidth
        validate={[
          required(),
          regex(
            /^[a-zA-Z0-9_]*$/,
            "Must be an alphanumeric string without spaces or punctuation",
          ),
        ]}
      />
      <BooleanInput label="Disabled" source="IsDisabled" />
      <ReferenceInput source="AdminId" reference="users" fullWidth>
        <AutocompleteInput
          fullWidth
          optionText="Email"
          validate={required()}
          filterToQuery={(searchText) => ({ Email: [searchText] })}
        />
      </ReferenceInput>
      <TextInput
        label="Email Domain"
        source="Domain"
        validate={[required(), regex(domainRegex)]}
        fullWidth
      />
      <SelectInput
        source="SubscriptionLevel"
        defaultValue="Free"
        validate={required()}
        choices={[
          { id: "Free", name: "Individual" },
          { id: "Essential", name: "Essential" },
          { id: "Professional", name: "Professional" },
          { id: "Enterprise", name: "Enterprise" },
        ]}
        fullWidth
      />
      <SelectInput
        source="Provider"
        defaultValue="Groopit"
        validate={required()}
        choices={[
          { id: "Groopit", name: "Groopit" },
          { id: "AzureAD", name: "Entra ID (Microsoft 365)" },
          { id: "Google", name: "Google" },
          { id: "Okta", name: "Okta" },
          { id: "OneLogin", name: "OneLogin" },
        ]}
        fullWidth
      />
      <ProviderData />
      <TextInput label="Slack Team ID" source="SlackTeamId" />
    </SimpleForm>
  );
};

export const TenantCreate = (props: CreateProps) => {
  // Read the post_id from the location
  return (
    <Create {...props}>
      <TenantForm />
    </Create>
  );
};

export const TenantEdit = (props: EditProps) => {
  const {
    record, // record fetched via dataProvider.getOne() based on the id from the location
  } = useEditController(props);
  return (
    <Edit {...props} title={`Tenant ${record?.Name}`}>
      <TenantForm />
    </Edit>
  );
};
